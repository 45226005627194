import React from 'react';
import * as Styled from './commonAccordionCLStyle';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { useContentfulAssets } from '../../../hooks/useContentfulAssets';
import { HeaderH3, MainContainer, Body, Columns } from '../../../styles';
import isExternalUrl, {
  addTrailingSlash,
  isExternalUrlHref,
} from '../../../utils';

const CommonAccordionCL = ({ sectionData, location }) => {
  React.useEffect(async () => {
    if (sectionData?.elementId === location?.hash?.replace('#', '')) {
      const waitedID = await new Promise((resolve) => {
        document?.getElementById(sectionData?.elementId) && resolve(true);
      });

      if (waitedID) {
        setTimeout(() => {
          window.location.href = `#${sectionData?.elementId}`;
        }, 500);
      }
    }
  }, [sectionData?.elementId]);

  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.HEADING_3]: (node, children) => (
        <Styled.HeaderH3>{children}</Styled.HeaderH3>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText>{children}</Styled.ParaText>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <Styled.ListContainer>{children}</Styled.ListContainer>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <Styled.OrderedListContainer>{children}</Styled.OrderedListContainer>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <Styled.ListText>{children}</Styled.ListText>
      ),
      [BLOCKS.HR]: () => <Styled.HorizontalBreak />,
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulAssets(node?.data?.target?.sys?.id);
        if (asset) {
          return asset?.node?.description ? (
            <a
              href={
                isExternalUrlHref(asset?.node?.description)
                  ? asset?.node?.description
                  : addTrailingSlash(
                      process.env.GATSBY_DOMAIN_URL + asset?.node?.description
                    )
              }
              target={
                isExternalUrl(asset?.node?.description) ? '_blank' : '_self'
              }
              rel="noreferrer"
            >
              <img src={asset?.node?.file?.url} alt={asset?.node?.title} />
            </a>
          ) : (
            <img src={asset?.node?.file?.url} alt={asset?.node?.title} />
          );
        }
      },
      [INLINES.HYPERLINK]: (node, children) => (
        <Styled.LinkStyle
          href={
            isExternalUrlHref(node?.data?.uri)
              ? node?.data?.uri
              : addTrailingSlash(
                  process.env.GATSBY_DOMAIN_URL + node?.data?.uri
                )
          }
          target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}
        >
          {children}
        </Styled.LinkStyle>
      ),
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        const asset = useContentfulAssets(node?.data?.target?.sys?.id);
        if (asset) {
          return (
            <Styled.LinkStyle href={asset?.node?.file?.url} target={'_blank'}>
              {children}
            </Styled.LinkStyle>
          );
        }
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  const optionsButtonMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText>{children}</Styled.ParaText>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <Styled.ListContainer>{children}</Styled.ListContainer>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <Styled.ListText>{children}</Styled.ListText>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <Styled.OrderedListContainer>{children}</Styled.OrderedListContainer>
      ),
      [BLOCKS.HR]: () => <Styled.HorizontalBreak />,
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulAssets(node?.data?.target?.sys?.id);
        if (asset) {
          return asset?.node?.description ? (
            <a
              href={
                isExternalUrlHref(asset?.node?.description)
                  ? asset?.node?.description
                  : addTrailingSlash(
                      process.env.GATSBY_DOMAIN_URL + asset?.node?.description
                    )
              }
              target={isExternalUrl(
                asset?.node?.description ? '_blank' : '_self'
              )}
            >
              <img src={asset?.node?.file?.url} alt={asset?.node?.title} />
            </a>
          ) : (
            <img src={asset?.node?.file?.url} alt={asset?.node?.title} />
          );
        }
      },
      [INLINES.HYPERLINK]: (node, children) => (
        <Styled.ButtonStyle
          href={
            isExternalUrlHref(node?.data?.uri)
              ? node?.data?.uri
              : addTrailingSlash(
                  process.env.GATSBY_DOMAIN_URL + node?.data?.uri
                )
          }
          target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}
        >
          {children}
        </Styled.ButtonStyle>
      ),
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        const asset = useContentfulAssets(node?.data?.target?.sys?.id);
        if (asset) {
          return (
            <Styled.ButtonStyle
              href={
                isExternalUrlHref(asset?.node?.file?.uri)
                  ? asset?.node?.file?.uri
                  : addTrailingSlash(
                      process.env.GATSBY_DOMAIN_URL + asset?.node?.file?.uri
                    )
              }
              target={'_blank'}
            >
              {children}
            </Styled.ButtonStyle>
          );
        }
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };

  const faqsArray = sectionData?.qna;

  return (
    <MainContainer>
      <Styled.MainContainer>
        <Styled.SpanStyle id={sectionData?.elementId}></Styled.SpanStyle>
        {sectionData?.description?.trim() && (
          <Styled.DescriptionText>
            {sectionData?.description}
          </Styled.DescriptionText>
        )}
        {sectionData?.header?.trim() && (
          <Styled.Header2>{sectionData?.header}</Styled.Header2>
        )}
        <Columns>
          <Styled.AccordionContainer flush alwaysOpen>
            <Styled.Container>
              {faqsArray?.map((item, index) => {
                return (
                  <Styled.AccordionItem eventKey={index} key={index}>
                    <Styled.AccordionHeader>
                      {item?.title}
                    </Styled.AccordionHeader>
                    <Styled.AccordionBody>
                      {item?.text &&
                        documentToReactComponents(
                          JSON.parse(item?.text?.raw),
                          optionsMainStyle
                        )}
                      {item?.buttons &&
                        documentToReactComponents(
                          JSON.parse(item?.buttons?.raw),
                          optionsButtonMainStyle
                        )}
                    </Styled.AccordionBody>
                  </Styled.AccordionItem>
                );
              })}
              <Styled.HorizontalBreakLessMargin />
            </Styled.Container>
          </Styled.AccordionContainer>
        </Columns>
      </Styled.MainContainer>
    </MainContainer>
  );
};
export default CommonAccordionCL;
